"use client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";

export const PricingComparisonFeatureModal = ({ feature }) => {
  if (!feature.description) return null;

  return (
    <Modal
      trigger={({ setOpen }) => (
        <Button
          className="w-3 h-3 ml-3 p-0 rounded-full"
          variant="ghost"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon
            className="text-xs"
            icon={faQuestion}
            aria-hidden="true"
          />
        </Button>
      )}
    >
      <div
        className="prose text-left p-10"
        dangerouslySetInnerHTML={{ __html: feature.description }}
      />
    </Modal>
  );
};
